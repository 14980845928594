
import { Injectable } from '@angular/core';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION } from '@ngrx/router-store';
import * as fromHistoryNavigationStore from './history-navigation.selectors';
import { SaveData, HistoryNavigationActionsTypes } from './history-navigation.actions';
import { IHistoryNavigationState } from './history-navigation.reducer';
import { Router } from '@angular/router';

@Injectable()
export class HistoryNavigationStoreEffects {

  constructor(
    private actions$: Actions,
    private storeNavigation$: Store<IHistoryNavigationState>,
    private router: Router,
  ) { }

  destroy$ = new Subject();
  lastUrl: string;

  @Effect({ dispatch: false })
  public routerNavigated$ = this.actions$.pipe(
    ofType(ROUTER_NAVIGATION),
    map((resp: any) => {
      const url = resp.payload.routerState.url;
      const cleanUrl = url.indexOf('?') > -1 ?
      url.split('?')[0] : url;
      if (!['/callback'].includes(cleanUrl)) {
        this.storeNavigation$.dispatch(new SaveData(cleanUrl));
      }
    }),
  );
}
