import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { HistoryNavigationStoreEffects } from './store/history-navigation/history-navigation.effects';

import * as fromHistoryNavigationReducer from './store/history-navigation/history-navigation.reducer';
import * as fromNavigationReducer from './store/navigation.reducer';

// Reducers
@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('navigation', fromNavigationReducer.reducer),
    StoreModule.forFeature('history-navigation', fromHistoryNavigationReducer.reducer),
    EffectsModule.forFeature([HistoryNavigationStoreEffects]),
  ],
})
  export class NavigationModule { }
