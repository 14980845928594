import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { Observable, of, Subject } from 'rxjs';
import { resolve } from 'url';

@Injectable({
  providedIn: 'root',
})
export class CloseModalService {

  public selectedData: Subject<any>;

  constructor() {
    this.selectedData = new Subject();
  }

  generateSelectedAgentData(hide: any) {
    this.selectedData.next(hide);
  }
}
