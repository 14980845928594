import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanActivate,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { AuthenticationService } from '@goldenfront/core/authentication';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BeneficioGuard implements CanActivate {
  loading = true;
  constructor(private auth: AuthenticationService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean | UrlTree> | boolean {
    // Aqui va estar la logica del cache
    const params = window.location.search;
    const getItem = localStorage.getItem('pjGmNxnkbv9MIXwj');
    if (!!params && !getItem) {
      const httpParams = new HttpParams({ fromString: params.split('?')[1] });
      const _id = httpParams.get('id');
      if (!!_id) {
        localStorage.setItem('pjGmNxnkbv9MIXwj', _id);
      }
    }
    return this.auth.isAuthenticated$.pipe(
      tap((loggedIn) => {
        if (!loggedIn) {
          this.auth.login(state.url);
        }
      }),
    );
  }
}
