import { HistoryNavigationActions, HistoryNavigationActionsTypes } from './history-navigation.actions';
import * as cloneDeep from 'lodash/cloneDeep';

export interface IHistoryNavigationState {
  urlRoute: string[];
  previousLink: string;
}

export const initialState: IHistoryNavigationState = {
  urlRoute: [],
  previousLink: null,
};

export function reducer(state = initialState, action: HistoryNavigationActions): IHistoryNavigationState {
  const newState = cloneDeep(state);
  switch (action.type) {

    case HistoryNavigationActionsTypes.SAVE_DATA:
      const lastUrl = newState.urlRoute[newState.urlRoute.length - 1];
      newState.urlRoute = (lastUrl !== action.payload)  ? [...newState.urlRoute, action.payload] : [...newState.urlRoute];
      return newState;

    case HistoryNavigationActionsTypes.SAVE_PREVIOUS_LINK:
      return {
        ...state,
        previousLink: action.payload,
      };

    case HistoryNavigationActionsTypes.NAVIGATE_BACK:
      if (newState.urlRoute.length > 1) {
        newState.urlRoute.pop();
      }
      return newState;

    case HistoryNavigationActionsTypes.CLEAR_DATA:
      return initialState;

    default:
      return state;
  }
}
