import { Injectable } from '@angular/core';
import { CacheConstants } from './cache.constants';
@Injectable({
  providedIn: 'root',
})
export class CacheService {
  cache: any;
  constants: any;
  constructor() {
    this.constants = CacheConstants;
    this.cache = {};
  }
  forms = new Map<string, any>();
  clearCache() {
    Object.keys(this.constants).forEach((item) => {
      this.cache[item] = null;
      localStorage.removeItem(item);
    });
  }
  set(key, value) {
    const val = this.handleSetObject(value);
    localStorage.setItem(key, val);
  }
  get(key) {
    return this.handleGetObject(localStorage.getItem(key));
  }
  update(key, nameAttr, newValue) {
    let value = this.handleGetObject(localStorage.getItem(key));
    value[nameAttr] = newValue;
    value = this.handleSetObject(value);
    localStorage.setItem(key, value);
  }
  private handleSetObject(value: any) {
    return encodeURIComponent(JSON.stringify({ value }));
  }
  private handleGetObject(dataRaw) {
    let data;
    let value;
    if (dataRaw) {
      data = JSON.parse(decodeURIComponent(dataRaw));
      value = data.value;
    }
    return value;
  }
}
