import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { HttpService } from './http/http.service';
import { ErrorInterceptor } from './http/error.interceptor';
import { CoreUtilModule } from './utils/utils.module';
import { CacheService } from './cache/cache.service';
import { SubscriptionService } from './services/subscription.service';
import { CookieService } from './services/cookie.service';
import { IntegrationService } from './integration/integration.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    CoreUtilModule,
  ],
  providers: [
    ErrorInterceptor,
    SubscriptionService,
    {
      provide: HttpClient,
      useClass: HttpService,
    },
    CacheService,
    CookieService,
    IntegrationService,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }
}
