import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromHistoryNavigation from './history-navigation.reducer';

export const selectHistoryNavigationState = createFeatureSelector<fromHistoryNavigation.IHistoryNavigationState>('history-navigation');
export const selectData = createSelector(selectHistoryNavigationState, (state: fromHistoryNavigation.IHistoryNavigationState) => {
  return state;
});

export const selectActualPageUrl = createSelector(selectHistoryNavigationState, (state: fromHistoryNavigation.IHistoryNavigationState) => {
  return state.urlRoute[state.urlRoute.length - 1];
});
