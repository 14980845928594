import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService } from '@goldenfront/core/authentication';
import { HttpParams } from '@angular/common/http';
import { RoutingService } from 'src/app/core/integration/routing.service';
import { NavigationService } from 'src/app/core/utils/services/navigation.service';
import { Observable } from 'rxjs';
import { LocalStorageService } from '@goldenfront/core/common';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() isLoading = false;

  isVisible: Boolean = true;
  isVisibleLogout: Boolean = true;
  public isShowingArrowButton$: Observable<boolean>;
  constructor(
    private auth: AuthenticationService,
    private navigationService: NavigationService,
    private localStorageService: LocalStorageService,
  ) {
    this.isShowingArrowButton$ = this.navigationService.showArrow$;
  }

  ngOnInit() {
    this.setAppParam();
    this.isVisible = this.setIfVisible();
    this.isVisibleLogout = this.setIfLogoutIsVisible();
  }

  setAppParam() {
    const params = window.location.search;
    const httpParams = new HttpParams({ fromString: params.split('?')[1] });
    httpParams.get('app') ? this.localStorageService.setItem('app', httpParams.get('app')) : this.localStorageService.removeItem('app');

  }

  setIfVisible() {
    const app = this.localStorageService.getItem('app');
    return app === 'credito' ? true : !this.auth.isQSMode;
  }

  setIfLogoutIsVisible() {
    return !this.auth.isQSMode;
  }

  logout() {
    this.auth.logout();
  }

  goBack() {
    this.navigationService.goBack();
  }

}
