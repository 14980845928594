import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class TokenRedirectorService {
  urlApiQuiero = 'https://api.quiero.com.ar/api/login/tokenNaranja';
  token: string = null;

  constructor() {}

  red(tk: string) {
    if (tk) {
      this.token = tk;
    }

    if (this.token) {
      this.redirectWithToken();
    }
  }

  redirectWithToken() {
    const mapForm = document.createElement('form');
    mapForm.setAttribute('ngNoForm', '');
    mapForm.method = 'POST';
    mapForm.action = this.urlApiQuiero;

    const mapInput = document.createElement('input');
    mapInput.type = 'hidden';
    mapInput.name = 'SToken';
    mapInput.setAttribute('value', this.token);

    mapForm.appendChild(mapInput);
    document.body.appendChild(mapForm);
    mapForm.submit();
  }
}
