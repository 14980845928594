import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZumoModule } from 'zumo';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderComponent } from './components/header/header.component';
import { BannerComponent } from './components/banner/banner.component';
import { NGFAuthDirectivesModule } from '@goldenfront/core/authentication';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    ZumoModule,
    NGFAuthDirectivesModule,
  ],
  exports: [TranslateModule, ZumoModule, HeaderComponent, BannerComponent],
  declarations: [HeaderComponent, BannerComponent],
})
export class SharedModule {}
