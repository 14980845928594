import * as _ from 'lodash';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanActivate,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { AuthenticationService } from '@goldenfront/core/authentication';
import { IntegrationService } from '../integration/integration.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
// @ts-ignore
export class ChannelGuard implements CanActivate {
  loading = true;
  constructor(
    private auth: AuthenticationService,
    private _integration: IntegrationService,
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean | UrlTree> | boolean {
    // Aqui va estar la logica del cache
    const params = window.location.search;
    const httpParams = new HttpParams({ fromString: params.split('?')[1] });

    const _id = httpParams.get('channel');
    const _source = httpParams.get('source');
    const _token = httpParams.get('token');

    if (!!_id && !_.isEmpty(_id)) {
      sessionStorage.setItem('channel', _id);
    }

    if (_source) {
      sessionStorage.setItem('source', _source);
    }

    if (_token) {
      this._integration.setIntegration(_source, _token);
    }

    return true;
  }
}
