import { Component, OnInit, Input } from '@angular/core';
import { ICustomDialogOptions, NGZModalDialogService, NGZCustomDialogService } from 'zumo';
import { Subject } from 'rxjs';
import { CloseModalService } from 'src/app/core/services/close-modal.service';
import { SubscriptionService } from 'src/app/core/services/subscription.service';
@Component({
  selector: 'app-unsubscription-reasons',
  templateUrl: './unsubscription-reasons.component.html',
  styleUrls: ['./unsubscription-reasons.component.scss'],
})
export class UnsubscriptionReasonsComponent implements ICustomDialogOptions {

  public disableBtn = false;
  isLoading = false;

  constructor(
    private closeModalService: CloseModalService,
    private subscriptionService: SubscriptionService,
    ) {
  }
  onActionClose: Subject<any>;
  data: any = {};
  selectedReason: String;

  change(e) {
    this.selectedReason = e.text;
    this.disableBtn = true;
  }

  back() {
    this.closeModalService.generateSelectedAgentData(null);
  }

  continue() {
    this.data.subject = true;
    this.isLoading = true;
    this.closeModalService.generateSelectedAgentData(this.selectedReason);
  }
}
