import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { CoreModule } from './core/core.module';
import { RootStoreModule } from './root-store/root-store.module';
import { TermsComponent } from './pages/terms/terms.component';
import { UnsubscriptionReasonsComponent } from './pages/unsubscription-reasons/unsubscription-reasons.component';
import { CustomPayloadParser } from './core/integration/custom-payload-parser.service';
import {
  NGFAuthenticationModule,
  AuthSettings,
  JWTPayloadParser,
} from '@goldenfront/core/authentication';
import { environment } from '../environments/environment';
import { env } from 'process';
import { NavigationModule } from './core/navigation/navigation.module';

// required for AOT compilation
export function customHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [AppComponent, TermsComponent, UnsubscriptionReasonsComponent],
  imports: [
    CommonModule,
    FormsModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: customHttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    HttpClientModule,
    CoreModule,
    SharedModule,
    RootStoreModule,
    AppRoutingModule,
    NavigationModule,
    NGFAuthenticationModule.forRoot({
      provider: {
        clientID: environment.auth0.clientID,
        domain: environment.auth0.domain,
        redirectUri: environment.auth0.urlAllowedToDoCallback,
        scope: environment.auth0.scope,
        audience: environment.auth0.audience,
      },
      authUrls: {
        urlAllowedToDoLogout: environment.auth0.urlAllowedToDoLogout,
      },
      configQS: {
        enabled: true,
        qsName: 'token',
        retryOptions: {
          delay: 1000,
          maxRetry: 2,
        },
      },
      storage: {
        type: 'cookie',
        storageKeyName: environment.auth0.cookie,
        cookiesOptions: environment.auth0.storageOptions,
      },
      JWTHandler: {
        provide: JWTPayloadParser,
        useClass: CustomPayloadParser,
      },
      features: {
        renewToken: {
          enabled: true,
          debug: environment.auth0.debugRenew,
        },
        headers: {},
      },
    } as AuthSettings),
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [TermsComponent, UnsubscriptionReasonsComponent],
})
export class AppModule {}
