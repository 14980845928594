import { Injectable } from '@angular/core';
import {
  AuthenticationService,
  OperationMode,
} from '@goldenfront/core/authentication';
import { CacheService } from '../cache/cache.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class IntegrationService {
  constructor(
    private cacheService: CacheService,
    private _authenticationService: AuthenticationService,
  ) {}

  setIntegration(type: string, _token: string = '') {
    if (type === 'app' || _token) {
      this.cacheService.set(this.cacheService.constants.INTEGRATION, {
        app: true,
        token: _token,
      });
    } else if (type === 'management') {
      this.cacheService.set(this.cacheService.constants.INTEGRATION, {
        management: true,
      });
    }
  }

  get getIntegrationApp(): boolean {
    return this._authenticationService.operationMode === OperationMode.QS;
  }

  get getTokenIntegration(): boolean {
    const integration = this.cacheService.get(
      this.cacheService.constants.INTEGRATION,
    );
    return integration && integration.app ? integration.token : false;
  }

  getIntegrationManagement(): boolean {
    const integration = this.cacheService.get(
      this.cacheService.constants.INTEGRATION,
    );

    return integration && integration.management;
  }

  get getAccountId(): string {
    const user_data = this.cacheService.get(
      this.cacheService.constants.USER_DATA,
    );

    return user_data ? user_data.accountId : '0';
  }

  exitFlowURL(): string {
    return environment.integration.appURL || environment.integration.nolURL;
  }
}
