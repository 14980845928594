import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '@goldenfront/core/common';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { IHistoryNavigationState } from '../../navigation/store/history-navigation/history-navigation.reducer';
import * as fromActionsHistoryNavigation from '../../navigation/store/history-navigation/history-navigation.actions';
import * as fromSelectorHistoryNavigation from '../../navigation/store/history-navigation/history-navigation.selectors';
import { filter } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private historyData: IHistoryNavigationState;
  private subjectShowArrow = new BehaviorSubject(false);
  public showArrow$ = this.subjectShowArrow.asObservable();
  whiteListHomePage = ['/no-insc', '/insc', '/'];
  mode = this.localStorageService.getItem('mode');
  constructor(
        private router: Router,
        private historyNavigationStore: Store<IHistoryNavigationState>,
        private localStorageService: LocalStorageService,
    ) {

    this.historyNavigationStore.dispatch(
            new fromActionsHistoryNavigation.SavePreviousLink(`${location.protocol}//${location.host}`));

    this.historyNavigationStore
            .select(fromSelectorHistoryNavigation.selectData)
            .pipe(filter(val => !!val))
            .subscribe((data) => {
              this.historyData = data;
              return this.subjectShowArrow.next(this.showArrowButton());
            });
  }

  saveHistoryNavigation(url: string): void {
    this.historyNavigationStore.dispatch(new fromActionsHistoryNavigation.SaveData(url));
  }

  goBack() {
    const lastIndexHistoryData = this.historyData.urlRoute.length - 1;
    const lastUrl = this.historyData.urlRoute[lastIndexHistoryData];
    const isHomePage = this.whiteListHomePage.includes(lastUrl);
    const isFirstNavigation = this.historyData.urlRoute.length === 0;

    if (isFirstNavigation || isHomePage) {
      this.mode === 'QS' ?
        window.location.href = 'https://www.naranja-webview-close-view.com/'
        : this.exit();
      return;
    }

    if (this.historyData && this.historyData.urlRoute.length > 0) {
      const previousFullUrlRoute = `${this.historyData.urlRoute[lastIndexHistoryData - 1]}`;
      const previousUrlParams = this.getPreviousUrlParams(previousFullUrlRoute);
      const previousUrlRoute = this.getPreviousUrl(previousFullUrlRoute);
      const params = this.parseParams(previousUrlParams);
      if (!previousFullUrlRoute.includes('callback')) {
        this.historyNavigationStore.dispatch(new fromActionsHistoryNavigation.NavigateBack());
        return this.router.navigate([previousUrlRoute], { queryParams: params, replaceUrl: true });
      }
    }
    return this.router.navigate(['/']);
  }

  getPreviousUrlParams(previousFullUrlRoute: string): string {
    return previousFullUrlRoute.indexOf('?') > -1 ?
      previousFullUrlRoute.split('?')[1] : null;
  }

  getPreviousUrl(previousFullUrlRoute: string): string {
    return previousFullUrlRoute.indexOf('?') > -1 ?
      previousFullUrlRoute.split('?')[0] : previousFullUrlRoute;
  }

  parseParams(querystring: string): Object {
    if (!querystring) {
      return null;
    }
    // parse query string
    const httpParams = new HttpParams({ fromString: querystring });
    const obj = {};
    // iterate over all keys
    for (const key of httpParams.keys()) {
      if (httpParams.getAll(key).length > 1) {
        obj[key] = httpParams.getAll(key);
      } else {
        obj[key] = httpParams.get(key);
      }
    }
    return obj;
  }

  exit() {
    setTimeout(() => {
      this.router.navigate(['/logout']);
    },         0);
  }

  private showArrowButton() {
    const app = this.localStorageService.getItem('app');
    if (app !== 'credito') {
      return false;
    }
    let lastUrl = this.historyData.urlRoute[this.historyData.urlRoute.length - 1];
    lastUrl = lastUrl && lastUrl.indexOf('?') > -1 ? lastUrl.split('?')[0] : lastUrl;
    const isHomePage = [...this.whiteListHomePage].includes(lastUrl);
    const isFirstNavigation = this.historyData.urlRoute.length === 1;
    if (isFirstNavigation && !isHomePage) {
      return false;
    }
    return true;
  }
}
