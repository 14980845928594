const FRONT_URL = 'https://e3-quiero.naranja.com';

export const environment = {
  production: false,
  currentURL: FRONT_URL,
  apiGateway: 'https://quiero.apinaranja.com/bff/',
  auth0: {
    domain: 'acceso.naranja.com',
    clientID: '6C2ldDy2UhtUMtOKZN3fQeUJbUBAwRA7',
    audience: 'https://naranja.com/nquiero/api',
    scope: 'email profile read.quierobff write.quierobff',
    urlAllowedToDoCallback: `${FRONT_URL}/callback`,
    urlAllowedToDoLogout:  `${FRONT_URL}/logout`,
    debugRenew: true,
    cookie: 'auth0user',
    storageOptions: {
      secure: false,
      sameSite: 'Strict',
      path: '/',
    },
  },
  bffSubscription: {
    name: 'subscription',
    inscription: 'subscribe',
    desuscripcion: 'unsubscribe',
  },
  integration: {
    appURL: 'https://www.naranja-webview-close-view.com',
    nolURL: 'https://e3-online.naranja.com/inicio',
  },
};
