import { NgModule } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { AuthenticationGuard } from '@goldenfront/core/authentication';
import { NavigatorService } from './core/utils/services/navigator.service';
import { BeneficioGuard } from './core/authentication/beneficio-guard.service';
import { ChannelGuard } from './core/authentication/channel-guard.service';

const commonsRoutes: Routes = [
  {
    path: 'callback',
    loadChildren: './pages/callback/callback.module#CallbackModule',
  },
  {
    path: 'logout',
    loadChildren: './pages/logout/logout.module#LogoutModule',
  },
  {
    path: 'not-found',
    loadChildren: './pages/not-found/not-found.module#NotFoundModule',
  },
  {
    path: 'page-status',
    loadChildren: './pages/page-status/page-status.module#PageStatusModule',
  },
  // Fallback when no prior route is matched
  { path: '**', redirectTo: 'not-found' },
];

export const desktopRoutes: Routes = [
  {
    path: '',
    canActivate: [ChannelGuard, AuthenticationGuard],
    loadChildren: './pages/home/home-desktop.module#HomeDesktopModule',
  },
  {
    path: 'insc',
    canActivate: [AuthenticationGuard],
    loadChildren: './pages/insc/insc-desktop.module#InscDesktopModule',
  },
  {
    path: 'no-insc',
    canActivate: [ChannelGuard],
    loadChildren: './pages/no-insc/no-insc-desktop.module#NoInscDesktopModule',
  },
  {
    path: 'no-tit',
    canActivate: [AuthenticationGuard],
    loadChildren: './pages/no-tit/no-tit-desktop.module#NoTitDesktopModule',
  },
  {
    path: 'tac',
    loadChildren: './pages/tac/tac.module#TacModule',
  },
  {
    path: 'product',
    canActivate: [BeneficioGuard],
    loadChildren:
      './pages/beneficio/beneficio-desktop.module#BeneficioDesktopModule',
  },
  {
    path: 'channel',
    canActivate: [ChannelGuard],
    loadChildren: './pages/channel/channel-desktop.module#ChannelDesktopModule',
  },
  ...commonsRoutes,
];

export const mobileRoutes: Routes = [
  {
    path: '',
    canActivate: [ChannelGuard, AuthenticationGuard],
    loadChildren: './pages/home/home-mobile.module#HomeMobileModule',
  },
  {
    path: 'insc',
    canActivate: [AuthenticationGuard],
    loadChildren: './pages/insc/insc-mobile.module#InscMobileModule',
  },
  {
    path: 'no-insc',
    canActivate: [ChannelGuard],
    loadChildren: './pages/no-insc/no-insc-mobile.module#NoInscMobileModule',
  },
  {
    path: 'no-tit',
    canActivate: [AuthenticationGuard],
    loadChildren: './pages/no-tit/no-tit-mobile.module#NoTitMobileModule',
  },
  {
    path: 'tac',
    loadChildren: './pages/tac/tac.module#TacModule',
  },
  {
    path: 'product',
    canActivate: [BeneficioGuard],
    loadChildren:
      './pages/beneficio/beneficio-mobile.module#BeneficioMobileModule',
  },
  {
    path: 'channel',
    canActivate: [ChannelGuard],
    loadChildren: './pages/channel/channel-mobile.module#ChannelMobileModule',
  },
  {
    path: 'unsubscription-reasons',
    canActivate: [AuthenticationGuard],
    loadChildren:
      './pages/unsubscription-reasons/unsubscription-reasons.module#UnsubscriptionReasonsModule',
  },
  ...commonsRoutes,
];

@NgModule({
  imports: [RouterModule.forRoot([...desktopRoutes, ...mobileRoutes])],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(
    private router: Router,
    private _navigatorService: NavigatorService,
  ) {
    // si no necesitan tener experiencia en mobile diferente eliminar lo siguiente
    if (this._navigatorService.isMobile()) {
      this.router.resetConfig(mobileRoutes);
    }
  }
}
