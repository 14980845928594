import { Component, ViewEncapsulation } from '@angular/core';
import { ICustomDialogOptions } from 'zumo';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class TermsComponent implements ICustomDialogOptions {
  onActionClose: Subject<any>;
  data: any;
}
