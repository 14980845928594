import {
  Component,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@goldenfront/core/authentication';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { CacheService } from './core/cache/cache.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  userActivity;
  userInactive: Subject<any> = new Subject();

  constructor(
    private translate: TranslateService,
    private router: Router,
    private auth: AuthenticationService,
    @Inject(PLATFORM_ID) private platformId: any,
    private _cacheService: CacheService,
  ) {}

  ngOnInit() {
    const language = this.getLang();
    if (['en'].indexOf(language) > -1) {
      this.translate.setDefaultLang(language);
    } else {
      this.translate.setDefaultLang('es');
    }
    this.setTimeout();
    this.userInactive.subscribe(() => {
      this.logout();
    });
  }

  onNavigate(url) {
    this.router.navigate([`${url}`]);
  }

  logout() {
    this.auth.logout();
  }

  setTimeout() {
    this.userActivity = setTimeout(() => {
      this.userInactive.next(undefined);
    },                             900000);
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

  // TODO: Revisar si lo obtenemos de la session, browser, server ?
  public getLang(): string {
    return 'es'; //
  }

  public switchLanguage(lang: string): void {
    this.translate.setDefaultLang(lang);
  }
}
