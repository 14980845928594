import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { TokenRedirectorService } from 'src/app/core/utils/services/token-redirector.service';
import { IDialogData, NGZDialogService } from 'zumo';

@Component({
  selector: 'app-banner',
  templateUrl: 'banner.component.html',
  styleUrls: ['banner.component.scss'],
})
export class BannerComponent implements OnInit {
  @Input() cardStyle: any = {};
  @Input() cardStyleBody: any = {};
  @Input() cardStyleMobile: any = {};
  @Input() cardStyleBodyMobile: any = {};
  @Input() position: string;
  @Input() banners: any;

  banner: any;
  isClickable = false;
  afterCloseSubjectGeneric = new Subject();
  redirectQSubject = new Subject();

  constructor(
    private tokenRedirectorService: TokenRedirectorService,
    private dialogService: NGZDialogService,
  ) {}

  ngOnInit() {
    this.redirectQSubject.asObservable().subscribe(() => {
      const tk = this.banner.token;
      this.tokenRedirectorService.red(tk);
    });

    if (this.position && this.banners) {
      const find = _.find(this.banners, (banner) => {
        return banner.position === this.position;
      });

      this.banner = find;
      this.render();
    }
  }

  render() {
    this.isClickable = this.banner && this.banner.action;
    // TODO figure out how to handle dynamic render
  }

  handleClick() {
    // TODO figure out how to handle dynamic click handlers
    if (this.banner.type === 'trade') {
      this.openRedirectDialog();
    }
  }

  openRedirectDialog(): void {
    const dialogData: IDialogData = {
      actionsButtons: [
        {
          text: 'Seguir en Naranja',
          onAction: this.afterCloseSubjectGeneric,
        },
        {
          text: 'Entendido',
          onAction: this.redirectQSubject,
        },
      ],
      title: 'Vamos a redirigirte fuera de Naranja',
      paragraph: 'Estás por entrar al sitio de Quiero!',
    };
    this.dialogService.showDialog(dialogData, this.afterCloseSubjectGeneric);
  }
}
