import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { map, retry } from 'rxjs/operators';

@Injectable()

export class SubscriptionService {

  constructor(
    private http: HttpClient,
    private router: Router,
  ) {

  }
  getSubscriptionData(identity): Observable<any> {
    return this.http.get(`${environment.apiGateway + environment.bffSubscription.name}/${identity}`);
  }

  subscribe(): Observable<any> {
    return this.http.post(`${environment.apiGateway + environment.bffSubscription.inscription}`, {});
  }

  unsubscribe(identity, selectedReason): Observable<any> {
    const options: any = { body: { reason: selectedReason } };
    const url = `${environment.apiGateway + environment.bffSubscription.name}/${identity}/${environment.bffSubscription.desuscripcion}`;
    return this.http.delete(url, options);
  }

  getBeneficio(identity, product): Observable<any> {
    return this.http.get(`${environment.apiGateway + environment.bffSubscription.name}/${identity}/product?id=${product}`);
  }

  subscribeToChannel(canal = null, legalTerms = null): Observable<any> {
    const data = ({ legalTerms, channel: canal });
    return this.http.post(`${environment.apiGateway + environment.bffSubscription.inscription}`, data).pipe(
      map((res: any) => {
        if (!res.success) { throw new Error(); }
        return res;
      }),
      retry(2));
  }
}
