import { NgModule, InjectionToken } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WindowService } from './services/window.service';
import { LocalStorageWrapper } from './services/local-storage-wrapper.service';
import { TokenRedirectorService } from './services/token-redirector.service';

export const WINDOW = new InjectionToken('tn-util-window');
export const LOCAL_STORAGE = new InjectionToken('tn-util-local-storage');

export function windowFactory(windowService: WindowService) {
  return windowService.nativeWindow;
}

export function localStorageFactory(localStorageWrapper: LocalStorageWrapper) {
  return localStorageWrapper.localStorage;
}

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
  ],
  providers: [
    WindowService,
    LocalStorageWrapper,
    TokenRedirectorService,
    { provide: WINDOW, useFactory: windowFactory, deps: [WindowService] },
    { provide: LOCAL_STORAGE, useFactory: localStorageFactory, deps: [LocalStorageWrapper] },
  ],
})
export class CoreUtilModule {}
